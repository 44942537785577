/**
 * Copyright (C) SiteVision AB 2002-2017, all rights reserved
 *
 * @author albin
 */
define(function(require) {
   'use strict';

   var
      $ = require('jquery'),
      _ = require('underscore');

   function sendRequest(options) {
      var hasDataObject = false;

      if (!options.data || _.isObject(options.data)) {
         hasDataObject = true;
         options.data = options.data || {};
         options.data.svAjaxReqParam = 'ajax'; // SV-23492 - https://stackoverflow.com/a/16152893/1397073
      }

      if (!/get/i.test(options.type) && hasDataObject) {
         options.processData = false;
         options.data = JSON.stringify(options.data);
      }

      return $.ajax(options);
   }

   var BASE_OPTIONS = {
         type: 'get',
         dataType: 'json'
      },
      JSON_OPTIONS = _.extend({}, {
         contentType: 'application/json; charset=utf-8'
      }, BASE_OPTIONS),
      DEFAULT_OPTIONS = {
         get: _.clone(BASE_OPTIONS),
         put: _.extend({}, JSON_OPTIONS, { type: 'put' }),
         post: _.extend({}, JSON_OPTIONS, { type: 'post' }),
         'delete': _.extend({}, JSON_OPTIONS, { type: 'delete' })
      };

   return {
      doGet: function(options) {
         return sendRequest(_.extend({}, DEFAULT_OPTIONS.get, options));
      },

      doPut: function(options) {
         return sendRequest(_.extend({}, DEFAULT_OPTIONS.put, options));
      },

      doPost: function(options) {
         return sendRequest(_.extend({}, DEFAULT_OPTIONS.post, options));
      },

      doDelete: function(options) {
         return sendRequest(_.extend({}, DEFAULT_OPTIONS['delete'], options));
      }
   };
});
